var CONFIG = {
  cleverTapKeyQa: 'TEST-58Z-KZ4-856Z',
  cleverTapKeyProd: 'WRK-W4W-R66Z',
  prodDomain: 'traders.ninjacart.in',
  freewheelDomain: 'freewheel.ninjacart.in',
  preprodDomain: 'traders.trafyn.xyz',
  hanselProdKey: '6b93d39ff2377b858d23a1375a9ae20c',
  hanselQaKey: 'eeab228a02bcfc7b1d5a19d7cf344ac7',
  hanselFreewheelKey: '7e0e1f4e703db382ae99d4d0a4234565',
  hanselPreprodKey: '324ee43a9c2912a4640caf622040772c',
};

function getHanselKey() {
  if (location.href.indexOf(CONFIG.prodDomain) > -1) {
    return CONFIG.hanselProdKey;
  } else if (location.href.indexOf(CONFIG.freewheelDomain) > -1) {
    return CONFIG.hanselFreewheelKey;
  } else if (location.href.indexOf(CONFIG.preprodDomain) > -1) {
    return CONFIG.hanselPreprodKey;
  } else {
    return CONFIG.hanselQaKey;
  }
}

function getClevertapKey() {
  if (location.href.indexOf(CONFIG.prodDomain) > -1) {
    return CONFIG.cleverTapKeyProd;
  } else {
    return CONFIG.cleverTapKeyQa;
  }
}

if (smartech) {
  // Hansel Integration
  try {
    smartech('create', 'ADGMOT35CHFLVDHBJNIG50K96AN655LRR8R4I40AMDFORGAVGP70');
    smartech('register', getHanselKey());
    smartech('identify', '');
  } catch (err) {
    console.log('Hansel erorr', err);
  }
}

//Clevertap Integration
var clevertap = { event: [], profile: [], account: [], onUserLogin: [], notifications: [], privacy: [], region: 'eu1' };
clevertap.account.push({ id: getClevertapKey() });
clevertap.privacy.push({ optOut: false });
clevertap.privacy.push({ useIP: false });
clevertap.spa = true;
(function () {
  try {
    var wzrk = document.createElement('script');
    wzrk.type = 'text/javascript';
    wzrk.async = true;
    wzrk.src =
      ('https:' == document.location.protocol
        ? 'https://d2r1yp2w7bby2u.cloudfront.net'
        : 'http://static.clevertap.com') + '/js/clevertap.min.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wzrk, s);
  } catch (error) {
    console.log('Clevertap Error!!!', error);
  }
})();
